type Config = {
  REACT_APP_TFE_API_URL: string;
  NEW_REACT_APP_TFE_API_URL: string;
  BASE_CDN_URL: string;
  AMPLITUDE_API_KEY: string;
  COMMUNITY_BANNER: string;
  STRIPE_KEY: string;
  SNOWBALL_WIDGET: number[];
  DEFAULT_USER_IMAGE: string;
  REACT_APP_TFE_NOTIFICATION_API_URL:string;
  PAID_REGISTRATION_EVENT_ID?: number[];
  HIDE_TICKET_TIME_EVENT_ID?: number[];
  NEXT_PUBLIC_IMPLICIT_CONSENT_ORGANISER_ID?: number[];
};

const envConfig: Config & { initialised: boolean } = {
  REACT_APP_TFE_API_URL: "",
  NEW_REACT_APP_TFE_API_URL: "",
  BASE_CDN_URL: "",
  AMPLITUDE_API_KEY: "2100594a387cfb265c9de360e8b83c75",
  COMMUNITY_BANNER: "banner/community_banner",
  STRIPE_KEY: "pk_test_dl4GfgzCAtq4QiM2EvpysexV",
  SNOWBALL_WIDGET: [27170],
  DEFAULT_USER_IMAGE: "comm_v2/images/profile/default_hash.png",
  initialised: false,
  REACT_APP_TFE_NOTIFICATION_API_URL:"",
  PAID_REGISTRATION_EVENT_ID: [],
  HIDE_TICKET_TIME_EVENT_ID: [],
  NEXT_PUBLIC_IMPLICIT_CONSENT_ORGANISER_ID: [],
};

const proxyEnvConfig: Config = new Proxy(envConfig, {
  get: (config, key: keyof Config) => {
    if (!config.initialised) {
      throw new Error("Config accessed before calling initializeConfig");
    }
    return config[key];
  }
});

const initializeConfig = (envName: string) => {
  switch (envName) {
    case "prod":
    case "production":
      envConfig.REACT_APP_TFE_API_URL = "https://api-v2.hubilo.com/api/v2/";
      envConfig.NEW_REACT_APP_TFE_API_URL = "https://ticketing-api.hubilo.com/api/v1";
      envConfig.BASE_CDN_URL = "https://cdn.hubilo.com/";
      envConfig.AMPLITUDE_API_KEY = "960be3819e363586bd0185150b95d0f9";
      // Stripe
      envConfig.STRIPE_KEY =
        "pk_live_51HuD25LNmwRTTkckDT3VxfxHYfJw6Ed9NEiDgTt1SAlkmLxj9jjrLIE9adgOSiZHH651p6hZ3gXi5ArImgHLAZ5U00TYw3Hpy6";
      // snoball widget
      envConfig.SNOWBALL_WIDGET = [11154];
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = "https://notifications.hubilo.com/api/v2/";
      envConfig.PAID_REGISTRATION_EVENT_ID = [21512];
      envConfig.HIDE_TICKET_TIME_EVENT_ID = [22014, 27782];
      envConfig.NEXT_PUBLIC_IMPLICIT_CONSENT_ORGANISER_ID = [6355641];
      break;
    case "v2release":
      envConfig.REACT_APP_TFE_API_URL = "https://api.v2release.demohubilo.com/api/v2/";
      envConfig.NEW_REACT_APP_TFE_API_URL = "https://ticketing.v2release.demohubilo.com/api/v1";
      envConfig.BASE_CDN_URL = "https://cdn.v2release.demohubilo.com/";
      envConfig.AMPLITUDE_API_KEY = "d46f1b0c2acd6ba1375e5b575eb42f42";
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = `https://notifications.${envName}.demohubilo.com/api/v2/`;
      break;
    case "devtwo":
      envConfig.REACT_APP_TFE_API_URL = `https://api.${envName}.demohubilo.com/api/v2/`;
      envConfig.NEW_REACT_APP_TFE_API_URL = `https://ticketing.${envName}.demohubilo.com/api/v1`;
      envConfig.BASE_CDN_URL = `https://cdn.${envName}.demohubilo.com/`;
      envConfig.AMPLITUDE_API_KEY = "d46f1b0c2acd6ba1375e5b575eb42f42";
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = `https://notifications.${envName}.demohubilo.com/api/v2/`;
      break;
    case "qatwo":
      envConfig.REACT_APP_TFE_API_URL = `https://api.${envName}.demohubilo.com/api/v2/`;
      envConfig.NEW_REACT_APP_TFE_API_URL = `https://ticketing.${envName}.demohubilo.com/api/v1`;
      envConfig.BASE_CDN_URL = `https://cdn.${envName}.demohubilo.com/`;
      envConfig.AMPLITUDE_API_KEY = "d46f1b0c2acd6ba1375e5b575eb42f42";
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = `https://notifications.${envName}.demohubilo.com/api/v2/`;
      break;
    case "v2swat":
      envConfig.REACT_APP_TFE_API_URL = "https://api.v2swat.demohubilo.com/api/v2/";
      envConfig.NEW_REACT_APP_TFE_API_URL = "https://ticketing.v2swat.demohubilo.com/api/v1";
      envConfig.BASE_CDN_URL = "https://cdn.v2swat.demohubilo.com/";
      // Stripe
      envConfig.STRIPE_KEY =
        "pk_test_51HuD25LNmwRTTkckUMKZZaCh1QJaOSfkYibaIB4Ti0X711RVOxbBoMCMdNYNzdzl5uFUM6Gc8uiTB5iRYPpiPxw6001ojZWdie";
      // snoball widget
      envConfig.SNOWBALL_WIDGET = [19848];
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = `https://notifications.${envName}.demohubilo.com/api/v2/`;
      break;
    default:
      envConfig.REACT_APP_TFE_API_URL = `https://api.${envName}.demohubilo.com/api/v2/`;
      envConfig.NEW_REACT_APP_TFE_API_URL = `https://ticketing.${envName}.demohubilo.com/api/v1`;
      envConfig.BASE_CDN_URL = `https://cdn.${envName}.demohubilo.com/`;
      envConfig.REACT_APP_TFE_NOTIFICATION_API_URL = `https://notifications.${envName}.demohubilo.com/api/v2/`;
      envConfig.PAID_REGISTRATION_EVENT_ID = envName === "base" ? [26917] : envName === "swateks" ?[16739] : [];
  }
  envConfig.initialised = true;
};

export { initializeConfig };

export default proxyEnvConfig;
